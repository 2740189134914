<script>
import {AgGridVue} from "ag-grid-vue";

export default {
  name: "ArticlePriceGrid",
  data() {
    return {
      gridApi: null,
      columnApi: null,
      debounceLength: null,
      defaultColDef: {
        floatingFilter: true,
      },
      columnDefs: [
        {
          field: "supplier.name", headerName: "Supplier", filter: true, minWidth: 160,
          cellStyle: {'text-align': 'left'}
        },
        {
          field: "title",
          minWidth: 300,
          resizable: true,
          headerName: "Article Title",
          cellStyle: {'text-align': 'left'},
          width: 400,
          cellRenderer: (params) => {

            if (!params.value) {
              return params.data.article.title;
            }
            return params.value;
          },
          filter: true
        },
        {
          field: "currency.code",
          minWidth: 100,
          resizable: true,
          headerName: "Currency",
          cellStyle: {'text-align': 'left'},
          width: 100,
        },
        {
          field: "base_currency_unit_price",
          minWidth: 100,
          resizable: true,
          headerName: "Unit Price",
          cellStyle: {'text-align': 'left'},
          width: 100,
        },
        {
          field: "price_list_id",
          minWidth: 100,
          resizable: true,
          headerName: "Price List",
          cellStyle: {'text-align': 'left'},
          width: 100,
          cellRenderer: (params) => {
            if (params.value) {
              return params.value;
            }
          }
        },
        {
          field: "is_promotional",
          minWidth: 100,
          resizable: true,
          headerName: "Price Type",
          cellStyle: {'text-align': 'left'},
          width: 100,
          cellRenderer: (params) => {
            return params.value ? 'Promotional' : 'Regular';
          }
        },
        {
          field: "price_date",
          minWidth: 120,
          resizable: true,
          headerName: "Age",
          cellStyle: {'text-align': 'left'},
          width: 200,
          cellRenderer: (params) => {
            return (this.getReadableDifference(new Date(params.value).getTime()));
          }
        },
      ]
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue
  },
  created() {
    this.debounceLength = 600; //ms
  },
  props: {
    rowData: Array
  },
  methods: {
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();
    }
  }
};
</script>

<template>

  <ag-grid-vue style="overflow-x: scroll; height: 700px"
               class="ag-theme-alpine"
               @grid-ready="onGridReady"
               :columnDefs="columnDefs"
               :defaultColDef="defaultColDef"
               :blockLoadDebounceMillis="debounceLength"
               :rowData="rowData">
  </ag-grid-vue>
</template>


<style lang="scss">

.align-middle {
  display: flex;
  align-items: center;
}
</style>