<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from "moment";


export default {
  name: "ArticlePriceTrendGraph",
  components: {
    DateRangePicker
  },
  data() {
    return {
      selectedSupplier: false,
      suppliers: [],
      dateRange: {
        startDate: null,
        endDate: null
      },
      seriesData: [],
      options: {}
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD/MM/YYYY") : "";
    }
  },
  mounted: function () {
    this.renderChart();
  },
  props: {
    prices: Object
  },
  methods: {
    renderChart() {
      let seriesData = [];
      let availableSuppliers = [];
      window.seriesAnnotations = new Map();

      try {
        Object.keys(this.prices).forEach((item) => {

          const price = this.prices[item];

          availableSuppliers[price.supplier.id] = {
            name: price.supplier.name,
            id: price.supplier.id
          }

          const time = (new Date(price.price_date)).getTime();

          if (this.selectedSupplier) {
            if (price.supplier.id !== this.selectedSupplier.id) {
              return;
            }
          }

          if (this.dateRange.startDate !== null) {
            const compareDate = moment(new Date(this.dateRange.startDate));
            const currentDate = moment(new Date(price.price_date));

            if (currentDate.isBefore(compareDate)) {
              return;
            }
          }

          if (this.dateRange.endDate !== null) {
            const compareDate = moment(new Date(this.dateRange.endDate));
            const currentDate = moment(new Date(price.price_date));

            if (currentDate.isAfter(compareDate)) {
              return;
            }
          }

          seriesData.push({
            x: time,
            y: price.base_currency_unit_price
          });

          window.seriesAnnotations.set(time, price);
        });


        this.seriesData =  [{
          name: 'Price',
          data: seriesData
        }];
        this.options = {
          markers: {
            size: 4,
            strokeWidth: 0,
            colors: '#0a0a0a'
          },
          chart: {
            toolbar: {
              show: false
            },
            type: 'line'
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            tickAmount: 'dataPoints',
            labels: {
              formatter: (value) => {
                  return moment(new Date(value)).format("DD/MM/YYYY")
                }
            }
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return '€ ' + value.toFixed(2)
              }
            }
          },
          tooltip: {
            y: {
              show: true,
              formatter: function (value, {dataPointIndex, w}) {

                let supplierName = '';
                const xValue = w.globals.seriesX[0][dataPointIndex];

                try {
                  const price = window.seriesAnnotations.get(xValue);

                  supplierName = price.supplier.name;

                } catch (e) {
                  supplierName = '';
                }

                return supplierName + ': €' + value;
              }
            }
          }
        }

        this.suppliers = availableSuppliers.filter((item) => {
          return item !== undefined;
        });

      }catch (e) {
        console.log(e.toString());
      }
    }
  },
  watch: {
    selectedSupplier: function () {
      this.renderChart();
    },
    dateRange: function() {
      this.renderChart();
    }

  }
};
</script>

<template>
  <div id="chartFilterContainer">
    <div class="formRow">
      <div class="formElement">
        <div class="formElement__label">
          <label>Supplier</label>
        </div>
        <div class="formElement__input">
          <v-select name="supplier-select" id="supplier-select" taggable :placeholder="`Please select`"
                    v-model="selectedSupplier" label="name"
                    :options="suppliers"></v-select>
        </div>
      </div>
      <div class="formElement">
        <div class="formElement__label">
          <label>Date range</label>
        </div>
        <div class="formElement__input">
          <date-range-picker
              ref="picker"
              :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
              :minDate="`01/01/2018`"
              :timePicker="false"
              :opens="`left`"
              :timePicker24Hour="false"
              :showDropdowns="true"
              :autoApply="true"
              v-model="dateRange"
          >
            <template v-slot:input="picker">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </template>
          </date-range-picker>
        </div>
      </div>
    </div>
    <div id="ArticlePriceChart">
      <apexchart type="line" :options="this.options" :series="this.seriesData"></apexchart>
    </div>
  </div>
</template>

<style lang="scss">
#ArticlePriceChart {
  height: 400px;
}

#chartFilterContainer {

  .formRow {
    padding: 5px 0;
    background: #fffbd4;
    border: solid 1px #fff696;
    border-radius: 5px;
  }

  .formElement {
    &:first-of-type {
      margin-right: 10px;
    }
  }

  .formElement__label {
    min-width: 90px;
    text-align: left;

    label {
      text-align: left;
      padding-left: 5px;
    }
  }

  .formElement__input {
    display: flex;
  }

}
</style>