<template>
  <Layout>
    <b-overlay :show="isLoading">
      <div v-if="currentArticle">
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-flex flex-column align-content-start">
              <h3 class="mb-0 font-size-18 title-container">
                {{ currentArticle.latina_article_id }}
              </h3>
              <p>{{ currentArticle.title }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-7">
            <b-card>
              <b-overlay>
                <b-card-title>
                  Prices
                </b-card-title>
                <b-card-body>
                  <ArticlePriceGrid :rowData="currentArticle.prices"/>
                </b-card-body>
              </b-overlay>
            </b-card>
          </div>
          <div class="col-5">
            <b-card>
              <b-card-title>
                Trend
              </b-card-title>
              <b-card-body>
                <p>Showing lowest price per date</p>
                <ArticlePriceTrendGraph :prices="currentArticle.seriesPrices"/>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import {mapState} from "vuex";
import ArticlePriceGrid from "../components/articles/ArticlePriceGrid";
import ArticlePriceTrendGraph from "../components/articles/ArticlePriceTrendGraph";

export default {
  name: 'ViewArticle',
  components: {
    Layout, ArticlePriceGrid, ArticlePriceTrendGraph
  },
  data() {
    return {
      articleLatinaId: this.$route.params.latinaId,
    }
  },
  created() {
    this.fetchArticleData();
    this.$store.dispatch('ancillaries/fetchSuppliers');
  },
  methods: {
    fetchArticleData() {
      this.$store.dispatch('articles/fetchSingleArticle', this.articleLatinaId);
    }
  },
  computed:
      mapState({
        isLoading: state => state.articles.isLoading,
        currentArticle: state => state.articles.currentArticle,
        suppliers: state => state.ancillaries.suppliers
      })

}
</script>
<style>
h3, p {
  text-align: left;
}
</style>